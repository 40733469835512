/** @jsx jsx */
import { jsx, Box, Styled, Flex, Grid } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import { ProjectStackImage } from './projectStackImage'

const AnimatedBox = animated(Box)

const ProjectDeployment = ({ projectDetails, projectCopyColor }) => {
  const { deploymentImageCount, deployment } = projectDetails

  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box sx={{ my: 5 }}>
        <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ mx: 0, bg: projectCopyColor, width: ['100px', '150px', '150px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${projectCopyColor}`, borderRadius: '2px' }} />
          <Box sx={{ mx: 2 }}>
            <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: projectCopyColor }}>Deployment</Styled.p>
          </Box>
          <Box sx={{ mx: 0, bg: projectCopyColor, width: ['100px', '150px', '150px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${projectCopyColor}`, borderRadius: '2px' }} />
        </Flex>
        <AnimatedBox
          style={subtitleFade}
          sx={{
            mt: [3, 3, 3],
            pt: [3, 4, 4],
            // pb: [3, 4, 4],
            px: [2, 5, 6],
            textAlign: 'center',
          }}
        >
          <Grid gap={5} columns={[`repeat(1, 200px)`, `repeat(${deploymentImageCount}, 200px)`, `repeat(${deploymentImageCount}, 200px)`]} variant="projectStack">
            {deployment.map((item) =>
              item.images.map((image, i) => {
                return <ProjectStackImage key={i} title={image.name} src={image.src.childImageSharp.fluid} />
              }),
            )}
          </Grid>
        </AnimatedBox>
        <AnimatedBox
          style={subtitleFade}
          sx={{
            width: ['100%', '92%', '92%'],
            mx: 'auto',
            pt: [3, 4, 4],
            pb: [3, 4, 4],
            px: [1, 5, 6],
            textAlign: 'center',
          }}
        >
          {deployment.map((item) =>
            item.content.map((content) => {
              return (
                <Styled.p sx={{ fontSize: ['2', '3', '3'], variant: 'text.about', color: projectCopyColor, mb: 0 }} key={content.title}>
                  {content.content}
                </Styled.p>
              )
            }),
          )}
        </AnimatedBox>
      </Box>
    </Fragment>
  )
}

export default ProjectDeployment
