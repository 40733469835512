/** @jsx jsx */
import { jsx, Styled, Flex } from 'theme-ui'
import { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { AuthorImage } from './authorImage'
import TwitterIcon from '../assets/twitterIcon'
import LinkedInIcon from '../assets/linkedInIcon'
import LensIcon from '../assets/lensLogo'

const AnimatedFlex = animated.div

function Author({ author, authorName, authorImage, authorTwitter, authorLinkedIn, authorEns, authorLens, authorGithub, authorWebsite }) {
  const [active, setActive] = useState({ hovered: false, duration: 4000 })

  const fromColor = '#1da1f2'
  const toColor = '#be4b52'

  const githubAnimation = useSpring({
    from: active.hovered ? { transform: 'rotate(0deg)' } : { transform: 'rotate(0deg)' },
    to: active.hovered ? { transform: 'rotate(90deg)' } : { transform: 'rotate(0deg)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  return (
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', py: 2 }}>
      <ul
        sx={{
          display: 'flex',
          alignItems: 'center',
          listStyle: 'none',
          m: '0',
          p: '0',
        }}
      >
        <li
          sx={{
            display: 'flex',
            alignItems: 'center',
            m: '0',
            p: '0',
          }}
        >
          <Flex>
            <AuthorImage authorName={authorName} authorImage={authorImage.childImageSharp.fluid} />
          </Flex>
          <Flex sx={{ mx: 2 }}>
            <Styled.h6 sx={{ mt: 2, pl: 1, variant: 'text.authorName' }}>{authorName || <em>Missing an author name</em>}</Styled.h6>
          </Flex>
        </li>
        <li
          sx={{
            display: 'flex',
            alignItems: 'center',
            m: '0',
            p: '0',
          }}
        >
          <Flex sx={{ width: '30px', height: 'inherit', mx: 1 }}>
            <a href={`https://twitter.com/${authorTwitter}`} title="Twitter">
              <TwitterIcon width="100%" height="100%" />
            </a>
          </Flex>
        </li>
        <li
          sx={{
            display: 'flex',
            alignItems: 'center',
            m: '0',
            p: '0',
          }}
        >
          <Flex sx={{ width: '20px', height: 'inherit', mx: 1 }}>
            <a href={`https://www.linkedin.com/in/${authorLinkedIn}`} title="LinkedIn">
              <LinkedInIcon width="100%" height="100%" />
            </a>
          </Flex>
        </li>
        <li
          sx={{
            display: 'flex',
            alignItems: 'center',
            m: '0',
            p: '0',
          }}
        >
          <Flex sx={{ width: '24px', height: 'inherit', mx: 2 }}>
            <a href={`https://www.lensfrens.xyz/${authorLens}`} title="Lens Protocol">
              <LensIcon width="100%" height="100%" />
            </a>
          </Flex>
        </li>
        {authorGithub !== 'none' && (
          <li
            sx={{
              display: 'flex',
              alignItems: 'center',
              m: '0',
              p: '0',
            }}
          >
            <AnimatedFlex style={githubAnimation} sx={{ display: 'flex', justifyContent: 'center', width: '24px', height: 'inherit', mx: 0 }}>
              <a href={authorGithub} title="Github">
                <FontAwesomeIcon
                  onMouseEnter={() => {
                    setActive({ hovered: true, duration: 500 })
                  }}
                  onMouseLeave={() => {
                    setActive({ hovered: false, duration: 500 })
                  }}
                  icon={faGithub}
                  size="lg"
                  color={active.hovered ? toColor : fromColor}
                />
              </a>
            </AnimatedFlex>
          </li>
        )}
      </ul>
    </Flex>
  )
}

export default Author
