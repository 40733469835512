/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState, useLayoutEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { isMobile } from 'react-device-detect'

function PatersonsLogo({ width = '340px', height = '80px', viewBoxX = '352', viewBoxY = '243', viewBoxWidth = 841.9, viewBoxWidthFactor = 0.18, viewBoxHeight = 595.3, viewBoxHeightFactor = 0.18 }) {
  const [active, setActive] = useState({ hovered: false, duration: 2250 })
  const [isOnMobile, setIsOnMobile] = useState()

  // Width AND Height control the size of the CANVAS, AND therefore the SVG inside it
  // viewBoxWidth AND viewBoxHeight control the 'cropping' of the SVG inside the Canvas (does NOT change the size of the Canvas)

  // viewBoxX AND viewBoxY control the position of the Canvas that 'holds' the SVG, which simulates 'cropping'

  // To adjust 'fit' inside a fixed width / height container:
  // set the Width AND Height to match the container
  // adjust viewBoxWidth AND viewBoxHeight to crop to the size you want
  // adjust viewBoxX and viewBoxY to position inside the container
  // set CSS to: contain: 'content'

  // The SVG's aspect ratio is scaled automatically unless we explicitly set the 'preserveAspectRatio' OR you can 'fix' the Width or Height (i.e. in pixels)

  const vBwidth = viewBoxWidth * viewBoxWidthFactor
  const vBheight = viewBoxHeight * viewBoxHeightFactor

  const containerAnimated = useSpring({
    opacity: 1,
    from: { opacity: 1 },
    config: { duration: 1250 },
  })

  const firstRingAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#B7A05E', transform: 'translate(15px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const secondRingAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#B7A05E', transform: 'translate(-15px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#B7A05E', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const pAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const aAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const tAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const eAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const rAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const sAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const oAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const nAnimated = useSpring({
    from: active.hovered ? { fill: '#4C4D4D', transform: 'translate(0px, 0px)' } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#B7A05E', transform: `translate(-15px, 0px)` } : { fill: '#4C4D4D', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const architectsAnimated = useSpring({
    from: active.hovered ? { fill: '#828383', transform: 'translate(0px, 0px)' } : { fill: '#828383', transform: 'translate(0px, 0px)' },
    to: active.hovered ? { fill: '#4C4D4D', transform: `translate(0px, 0px)` } : { fill: '#828383', transform: 'translate(0px, 0px)' },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  // Detect if device is a mobile
  useLayoutEffect(() => {
    if (isMobile) {
      return setIsOnMobile(true)
    }
    return setIsOnMobile(false)
  }, [active])

  // Render animation if device is not a mobile
  const renderAnimation = () => {
    if (isOnMobile) {
      return setActive({ hovered: false, duration: 500 })
    }
    return setActive({ hovered: true, duration: 500 })
  }

  return (
    <animated.svg
      version="1.1"
      id="Layer_1"
      data-name="Logo — FIXED"
      xmlns="http://www.w3.org/2000/svg"
      // preserveAspectRatio
      viewBox={`${viewBoxX} ${viewBoxY} ${vBwidth} ${vBheight}`}
      width={width}
      height={height}
      // x="0px"
      // y="0px"
      onMouseEnter={isMobile ? () => false : renderAnimation}
      onMouseLeave={() => {
        setActive({ hovered: false, duration: 500 })
      }}
      style={containerAnimated}
      sx={{ my: 3 }}
    >
      {/* 1st Ring */}
      <animated.path
        id="Ring_1"
        style={firstRingAnimated}
        fill="#4C4D4D"
        d="M286.9,269.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9s27.9-12.5,27.9-27.9S302.3,269.8,286.9,269.8
	 M286.9,327.8c-16.6,0-30.1-13.5-30.1-30.1c0-16.6,13.5-30.1,30.1-30.1s30.1,13.5,30.1,30.1C317,314.3,303.5,327.8,286.9,327.8"
      />
      {/* 2nd Ring */}
      <animated.path
        id="Ring_2"
        style={secondRingAnimated}
        fill="#B7A05E"
        d="M317.5,269.8c-15.4,0-27.9,12.5-27.9,27.9s12.5,27.9,27.9,27.9s27.9-12.5,27.9-27.9S332.9,269.8,317.5,269.8
	 M317.5,327.8c-16.6,0-30.1-13.5-30.1-30.1c0-16.6,13.5-30.1,30.1-30.1s30.1,13.5,30.1,30.1C347.6,314.3,334.1,327.8,317.5,327.8"
      />
      {/* Letter P - Paterson */}
      <animated.path
        id="Letter_P"
        style={pAnimated}
        fill="#4C4D4D"
        d="M375.2,282h1.1l4.1,0c0.7,0,1.4-0.1,2-0.4c0.6-0.2,1.2-0.6,1.7-1.2c0.5-0.5,0.9-1.1,1.1-1.7
	c0.3-0.6,0.4-1.3,0.4-2c0-0.7-0.1-1.4-0.4-2c-0.2-0.6-0.6-1.1-1.1-1.7c-0.5-0.5-1.1-0.9-1.7-1.2c-0.6-0.3-1.3-0.4-2-0.4h-4.1h-1.1
	v1.1v4.2v4.2V282z M371.7,286.4v-9.7v-8.6h4.5h4.1c1.2,0,2.3,0.2,3.3,0.6c1,0.4,1.9,1,2.8,1.9c0.8,0.8,1.5,1.8,1.9,2.8
	c0.4,1,0.6,2.1,0.6,3.3c0,1.2-0.2,2.3-0.6,3.3c-0.4,1-1.1,1.9-1.9,2.8c-0.9,0.8-1.8,1.5-2.8,1.9c-1,0.4-2.1,0.6-3.3,0.6h-4.1h-1.1
	v1.1v7.2h-3.4V286.4z"
      />
      {/* Letter A - Paterson */}
      <animated.path
        id="Letter_A"
        style={aAnimated}
        fill="#4C4D4D"
        d="M405.2,286.7h4.2c-0.7-1.6-1.4-3.3-2.1-5.2c-0.7-1.8-1.4-3.6-2.1-5.2c-0.7,1.6-1.4,3.3-2.1,5.2
	c-0.7,1.8-1.4,3.6-2.1,5.2H405.2z M416.2,293.6h-3.7l-1.9-4.2h-5.4h-5.4l-1.9,4.2h-3.7l11-25.7L416.2,293.6z"
      />
      {/* Letter T */}
      <animated.polygon
        id="Letter_T"
        style={tAnimated}
        fill="#4C4D4D"
        points="433.4,268.1 427.8,268.1 422.1,268.1 422.1,271.5 427.8,271.5 428.9,271.5 428.9,272.6 428.9,293.6
	432.3,293.6 432.3,272.6 432.3,271.5 433.4,271.5 439.1,271.5 439.1,268.1 "
      />
      {/* Letter E */}
      <animated.polygon
        id="Letter_E"
        style={eAnimated}
        fill="#4C4D4D"
        points="450.2,268.1 450.2,293.7 454.7,293.7 464.8,293.7 464.8,290.3 454.7,290.3 453.7,290.3 453.7,289.2
	453.7,283.7 453.7,282.6 454.7,282.6 462,282.6 462,279.2 454.7,279.2 453.7,279.2 453.7,278.1 453.7,272.5 453.7,271.5
	454.7,271.5 464.8,271.5 464.8,268.1 "
      />
      {/* Letter R - Paterson */}
      <animated.path
        id="Letter_R"
        style={rAnimated}
        fill="#4C4D4D"
        d="M484.4,281.3L484.4,281.3c0.7,0,1.3-0.1,1.9-0.3c0.5-0.2,1-0.6,1.5-1.1c0.5-0.5,0.9-1.1,1.1-1.6
	c0.2-0.5,0.3-1.2,0.3-1.9c0-0.7-0.1-1.3-0.3-1.9c-0.2-0.5-0.6-1-1.1-1.5v0c-0.5-0.5-1.1-0.8-1.9-1.1c-0.7-0.2-1.5-0.4-2.3-0.4h-3.1
	h-1.1v1.1v7.6v1.1h1.1l1.3,0h0.9H484.4z M475.9,268.1h8c1.3,0,2.5,0.2,3.6,0.7c1.1,0.4,2,1,2.9,1.8c0.9,0.9,1.5,1.7,1.8,2.5
	c0.4,0.8,0.5,1.8,0.5,3v0.7c0,0.9-0.1,1.7-0.4,2.4c-0.3,0.7-0.6,1.4-1.2,2.2c-0.4,0.6-0.9,1.1-1.3,1.4c-0.4,0.3-0.9,0.6-1.6,0.8
	l-1.2,0.5l0.7,1.1l5.4,8.3H489l-5.8-8.9h-3.8v8.9h-3.4L475.9,268.1z"
      />
      {/* Letter S - PaterSon */}
      <animated.path
        id="Letter_S"
        style={sAnimated}
        fill="#4C4D4D"
        d="M512.4,290.1c0.5-0.2,1-0.5,1.4-0.8c0.4-0.3,0.7-0.7,1-1.2c0.3-0.5,0.4-1,0.4-1.5c0-0.5-0.1-0.9-0.3-1.3
	c-0.2-0.4-0.5-0.7-0.8-1c-0.3-0.3-0.7-0.5-1.2-0.8c-0.5-0.2-0.9-0.4-1.3-0.6c-0.4-0.2-0.9-0.3-1.4-0.4c-1.1-0.3-2.1-0.7-3-1.2
	c-0.9-0.5-1.7-1.1-2.3-1.9c-0.5-0.5-0.8-1.2-1.1-1.8c-0.2-0.7-0.4-1.4-0.4-2.1c0-1,0.2-2,0.6-2.8c0.4-0.9,0.9-1.7,1.6-2.4
	c0.7-0.7,1.5-1.2,2.4-1.6c0.9-0.4,1.9-0.6,2.9-0.6c0.9,0,1.8,0.2,2.6,0.5c0.8,0.3,1.6,0.8,2.2,1.3c1.1,1,1.9,2.1,2.3,3.6l-3.4,0.7
	c-0.2-0.5-0.5-1-0.9-1.4c-0.8-0.8-1.7-1.1-2.7-1.1c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-0.9,0.5-1.2,0.8c-0.4,0.4-0.6,0.8-0.8,1.3
	c-0.2,0.5-0.3,1-0.3,1.5c0,0.4,0.1,0.7,0.2,1.1c0.1,0.3,0.3,0.6,0.4,0.8c0.2,0.2,0.4,0.5,0.7,0.7c0.3,0.2,0.5,0.4,0.8,0.5
	c0.3,0.1,0.6,0.3,0.9,0.4c0.4,0.1,0.7,0.3,0.9,0.3c0.3,0.1,0.6,0.2,0.9,0.3c0.4,0.1,0.6,0.2,0.8,0.3c0.6,0.2,1.2,0.5,1.8,0.8
	c0.6,0.3,1.1,0.7,1.6,1.1c0.5,0.4,1,0.9,1.3,1.4s0.7,1,0.9,1.7c0.2,0.6,0.3,1.3,0.3,1.9c0,1-0.2,2-0.7,2.9c-0.4,0.9-1,1.7-1.7,2.3
	c-0.7,0.7-1.5,1.2-2.5,1.6c-0.9,0.4-1.9,0.6-2.9,0.6c-1.1,0-2.2-0.2-3.2-0.7c-1-0.4-1.9-1.1-2.7-1.9c-0.8-0.8-1.3-1.8-1.6-2.8
	l3.4-0.7c0.3,0.8,0.9,1.4,1.6,1.9c0.8,0.5,1.6,0.7,2.4,0.7C511.4,290.4,511.9,290.3,512.4,290.1"
      />
      {/* Letter O - Paterson */}
      <animated.path
        id="Letter_O"
        style={oAnimated}
        fill="#4C4D4D"
        d="M538.1,272.1c-1.2,0.5-2.2,1.2-3.1,2.1c-0.9,0.9-1.6,2-2.1,3.1c-0.5,1.1-0.7,2.3-0.7,3.6c0,1.3,0.2,2.5,0.7,3.6
	c0.4,1.1,1.1,2.1,2.1,3.1c0.9,0.9,1.9,1.6,3.1,2.1c1.1,0.5,2.4,0.7,3.7,0.7c1.3,0,2.5-0.2,3.6-0.7c1.1-0.5,2.2-1.2,3.1-2.1
	c0.9-0.9,1.6-2,2.1-3.1c0.5-1.1,0.7-2.3,0.7-3.6c0-1.3-0.2-2.5-0.7-3.6c-0.5-1.1-1.2-2.1-2.1-3.1c-0.9-0.9-1.9-1.6-3.1-2.1
	c-1.1-0.5-2.3-0.7-3.6-0.7C540.4,271.4,539.2,271.6,538.1,272.1 M546.7,268.9c1.5,0.6,2.9,1.6,4.2,2.9c1.3,1.3,2.2,2.7,2.9,4.2
	c0.6,1.5,1,3.2,1,5c0,1.8-0.3,3.4-1,5c-0.6,1.5-1.6,2.9-2.9,4.2c-1.3,1.3-2.7,2.2-4.2,2.8c-1.5,0.6-3.2,0.9-5,0.9
	c-1.8,0-3.4-0.3-5-0.9c-1.5-0.6-2.9-1.6-4.2-2.8c-1.3-1.3-2.2-2.7-2.8-4.2c-0.6-1.5-0.9-3.1-0.9-5c0-1.8,0.3-3.5,0.9-5
	c0.6-1.5,1.6-2.9,2.8-4.2c1.3-1.3,2.7-2.2,4.2-2.9c1.5-0.6,3.1-0.9,5-0.9C543.5,267.9,545.2,268.2,546.7,268.9"
      />
      {/* Letter N */}
      <animated.polygon
        id="Letter_N"
        style={nAnimated}
        fill="#4C4D4D"
        points="565.9,293.7 569.3,293.7 569.3,278.3 569.3,276.4 585.9,294.1 585.9,268.1 582.5,268.1 582.5,282.4
	582.5,285.4 565.9,267.9 "
      />
      {/* Letter A - Architects */}
      <animated.path
        id="Letter_A_Arch"
        style={architectsAnimated}
        fill="#828383"
        d="M377.7,322.6h3.4c-0.6-1.3-1.1-2.7-1.7-4.2c-0.6-1.5-1.1-2.9-1.7-4.3c-0.6,1.3-1.1,2.7-1.7,4.3
	c-0.6,1.5-1.1,2.9-1.7,4.2H377.7z M386.7,328.3h-3l-1.5-3.4h-4.5h-4.4l-1.6,3.4h-3l9-21.1L386.7,328.3z"
      />
      {/* Letter R - Architects */}
      <animated.path
        id="Letter_R_Arch"
        style={architectsAnimated}
        fill="#828383"
        d="M402.8,318.2L402.8,318.2c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.5,1.2-0.9c0.4-0.4,0.7-0.9,0.9-1.3
	c0.2-0.4,0.3-1,0.3-1.6c0-0.6-0.1-1.1-0.3-1.5c-0.2-0.4-0.5-0.8-0.9-1.3v0c-0.4-0.4-0.9-0.7-1.5-0.9c-0.6-0.2-1.2-0.3-1.9-0.3h-2.6
	h-0.9v0.9v6.3v0.9h0.9l1.1,0h0.7H402.8z M395.8,307.4h6.5c1,0,2,0.2,3,0.5c0.9,0.3,1.7,0.8,2.4,1.5c0.7,0.7,1.2,1.4,1.5,2.1
	c0.3,0.7,0.5,1.5,0.5,2.5v0.6c0,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.5,1.2-1,1.8c-0.4,0.5-0.7,0.9-1,1.1c-0.3,0.2-0.7,0.5-1.3,0.7l-1,0.4
	l0.6,0.9l4.4,6.9h-3.5l-4.8-7.3h-3.1v7.3h-2.8L395.8,307.4z"
      />
      {/* Letter C - Architects */}
      <animated.path
        id="Letter_C_Arch"
        style={architectsAnimated}
        fill="#828383"
        d="M428.9,307.3c0.7,0,1.4,0.1,2.1,0.2c0.7,0.1,1.3,0.4,2,0.6c0.7,0.3,1.3,0.6,1.8,1c0.3,0.2,0.6,0.5,0.9,0.7l-2,2
	c-0.6-0.5-1.2-0.9-1.9-1.1c-0.9-0.4-1.9-0.6-3-0.6c-1.1,0-2,0.2-3,0.6c-0.9,0.4-1.8,1-2.5,1.7c-0.7,0.7-1.3,1.6-1.7,2.5h0
	c-0.4,0.9-0.6,1.9-0.6,3c0,1.1,0.2,2.1,0.6,3c0.4,0.9,1,1.8,1.7,2.5c0.7,0.7,1.6,1.3,2.5,1.7c0.9,0.4,1.9,0.6,3,0.6
	c1.1,0,2.1-0.2,3-0.6c0.7-0.3,1.3-0.7,1.9-1.1l2,2c-0.3,0.2-0.6,0.5-0.9,0.7c-0.6,0.4-1.2,0.7-1.8,1c-0.7,0.3-1.3,0.5-2,0.6
	c-0.7,0.1-1.4,0.2-2.1,0.2c-1.5,0-2.8-0.3-4.1-0.8c-1.3-0.5-2.4-1.3-3.4-2.3c-1.1-1.1-1.8-2.2-2.3-3.4c-0.5-1.2-0.8-2.6-0.8-4.1
	c0-1.5,0.3-2.8,0.8-4.1c0.5-1.2,1.3-2.4,2.3-3.5c1.1-1.1,2.2-1.8,3.4-2.4c0.9-0.4,1.9-0.6,2.9-0.7H428.9z"
      />
      {/* Letter H - Architects */}
      <animated.polygon
        id="Letter_H_Arch"
        style={architectsAnimated}
        fill="#828383"
        points="457.1,307.4 457.1,315.6 457.1,316.4 456.3,316.4 448.7,316.4 447.8,316.4 447.8,315.6 447.8,307.4
	445,307.4 445,328.3 447.8,328.3 447.8,320.1 447.8,319.2 448.7,319.2 456.3,319.2 457.1,319.2 457.1,320.1 457.1,328.3
	459.9,328.3 459.9,307.4 "
      />
      {/* Letter I - Architects */}
      <rect id="Letter_I_Arch" x="469.9" y="307.4" fill="#828383" width="2.8" height="20.9" />
      {/* Letter T - ArchiTects */}
      <animated.polygon
        id="Letter_T_Arch"
        style={architectsAnimated}
        fill="#828383"
        points="491.2,307.4 486.6,307.4 481.9,307.4 481.9,310.1 486.6,310.1 487.5,310.1 487.5,311 487.5,328.3
	490.3,328.3 490.3,311 490.3,310.1 491.2,310.1 495.9,310.1 495.9,307.4 "
      />

      {/* Letter E - ArchitEcts */}
      <animated.polygon
        id="Letter_E_Arch"
        style={architectsAnimated}
        fill="#828383"
        points="505,307.3 505,328.4 508.7,328.4 516.9,328.4 516.9,325.6 508.7,325.6 507.8,325.6 507.8,324.7
	507.8,320.1 507.8,319.2 508.7,319.2 514.7,319.2 514.7,316.4 508.7,316.4 507.8,316.4 507.8,315.6 507.8,311 507.8,310.1
	508.7,310.1 516.9,310.1 516.9,307.3 "
      />
      {/* Letter C - ArchiteCts */}
      <animated.path
        id="Letter_C_Arch"
        style={architectsAnimated}
        fill="#828383"
        d="M535.9,307.3c0.7,0,1.4,0.1,2.1,0.2c0.7,0.1,1.3,0.4,2,0.6c0.7,0.3,1.3,0.6,1.8,1c0.3,0.2,0.6,0.5,0.9,0.7l-2,2
	c-0.6-0.5-1.2-0.9-1.9-1.1c-0.9-0.4-1.9-0.6-3-0.6c-1,0-2,0.2-3,0.6c-0.9,0.4-1.8,1-2.5,1.7c-0.8,0.7-1.3,1.6-1.7,2.5h0
	c-0.4,0.9-0.6,1.9-0.6,3c0,1.1,0.2,2.1,0.6,3c0.4,0.9,1,1.8,1.7,2.5c0.7,0.7,1.6,1.3,2.5,1.7c0.9,0.4,1.9,0.6,3,0.6
	c1.1,0,2.1-0.2,3-0.6c0.7-0.3,1.3-0.7,1.9-1.1l2,2c-0.3,0.2-0.6,0.5-0.9,0.7c-0.6,0.4-1.2,0.7-1.8,1c-0.7,0.3-1.3,0.5-2,0.6
	c-0.7,0.1-1.4,0.2-2.1,0.2c-1.5,0-2.8-0.3-4.1-0.8c-1.3-0.5-2.4-1.3-3.4-2.3c-1.1-1.1-1.8-2.2-2.3-3.4c-0.5-1.2-0.8-2.6-0.8-4.1
	c0-1.5,0.3-2.8,0.8-4.1c0.5-1.2,1.3-2.4,2.3-3.5c1.1-1.1,2.2-1.8,3.4-2.4c0.9-0.4,1.9-0.6,2.9-0.7H535.9z"
      />
      {/* Letter T - ArchitecTs */}
      <animated.polygon
        id="Letter_T_Arch"
        style={architectsAnimated}
        fill="#828383"
        points="560.4,307.4 555.8,307.4 551.1,307.4 551.1,310.1 555.8,310.1 556.7,310.1 556.7,311 556.7,328.3
	559.5,328.3 559.5,311 559.5,310.1 560.4,310.1 565.1,310.1 565.1,307.4 "
      />
      {/* Letter S - ArchitectS */}
      <animated.path
        id="Letter_S_Arch"
        style={architectsAnimated}
        fill="#828383"
        d="M580.8,325.4c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.3c0-0.4-0.1-0.7-0.2-1.1
	c-0.2-0.3-0.4-0.6-0.6-0.8c-0.3-0.2-0.6-0.4-1-0.6c-0.4-0.2-0.7-0.4-1.1-0.5c-0.3-0.1-0.7-0.2-1.1-0.4c-0.9-0.3-1.8-0.6-2.5-1
	c-0.7-0.4-1.4-0.9-1.9-1.6c-0.4-0.4-0.7-1-0.9-1.5c-0.2-0.6-0.3-1.1-0.3-1.8c0-0.8,0.2-1.6,0.5-2.3c0.3-0.7,0.7-1.4,1.3-1.9
	c0.6-0.6,1.2-1,1.9-1.3s1.5-0.5,2.3-0.5c0.7,0,1.5,0.1,2.1,0.4s1.3,0.6,1.8,1.1c0.9,0.8,1.5,1.8,1.9,2.9l-2.8,0.6
	c-0.2-0.4-0.4-0.8-0.8-1.2c-0.6-0.6-1.4-0.9-2.2-0.9c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.5,0.6-0.7,1
	c-0.2,0.4-0.2,0.8-0.2,1.2c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.5,0.4,0.7c0.1,0.2,0.3,0.4,0.6,0.6c0.2,0.2,0.5,0.3,0.7,0.4
	c0.2,0.1,0.5,0.2,0.8,0.4s0.6,0.2,0.8,0.3c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.7,0.2c0.5,0.2,1,0.4,1.5,0.6
	c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.3,0.8,0.7,1.1,1.1c0.3,0.4,0.5,0.9,0.7,1.4c0.2,0.5,0.3,1,0.3,1.6c0,0.8-0.2,1.6-0.5,2.4
	c-0.4,0.8-0.8,1.4-1.4,1.9c-0.6,0.5-1.3,1-2,1.3c-0.8,0.3-1.6,0.5-2.4,0.5c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.4-1.6-0.9-2.2-1.6
	c-0.6-0.7-1.1-1.4-1.3-2.3l2.8-0.6c0.3,0.6,0.7,1.1,1.3,1.5c0.6,0.4,1.3,0.6,2,0.6C580,325.6,580.4,325.5,580.8,325.4"
      />
    </animated.svg>
  )
}

export default PatersonsLogo
