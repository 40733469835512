/** @jsx jsx */
import { jsx, Box, Flex, Styled, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import { ProjectHeroImage } from './projectHeroImage'
import Container from './container'
import Author from './author'
import ProjectAnimation from './projectAnimation'

const AnimatedBox = animated(Box)

const ProjectHero = ({ projectDetails }) => {
  const { tag, summary, heroImage, heroImageName, author, authorName, authorImage, authorTwitter, authorLinkedIn, authorEns, authorLens, authorGithub, authorWebsite, animation, animationName, internetStage, contractAddress } = projectDetails

  const context = useThemeUI()
  const { theme } = context

  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })
  const animationFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 600,
  })

  return (
    <Fragment>
      <Container>
        <AnimatedBox
          style={headingFade}
          sx={{
            textAlign: 'center',
            mt: [4, 5, 6],
          }}
        >
          {internetStage === 'web3' && (
            <Flex sx={{ justifyContent: 'center', mb: 3 }}>
              <Box sx={{ width: '72px', px: 2, border: `1px solid ${theme.colors.web3}`, borderRadius: '4px', backgroundColor: `${theme.colors.web3}` }}>
                <Styled.p sx={{ color: `${theme.colors.projectCopy}` }}>Web3</Styled.p>
              </Box>
            </Flex>
          )}
          <Styled.h5 sx={{ variant: 'text.subheadingSecondary' }}>Featured Project</Styled.h5>
        </AnimatedBox>
        {author && (
          <Fragment>
            <AnimatedBox
              style={headingFade}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                mt: [4, 4, 4, 4],
              }}
            >
              <Styled.h6 sx={{ variant: 'text.author', pr: 3 }}>Written by:</Styled.h6>
              <Author author={author} authorName={authorName} authorImage={authorImage} authorTwitter={authorTwitter} authorLinkedIn={authorLinkedIn} authorEns={authorEns} authorLens={authorLens} authorGithub={authorGithub} authorWebsite={authorWebsite} />
            </AnimatedBox>
          </Fragment>
        )}
        <AnimatedBox
          style={headingFade}
          sx={{
            textAlign: 'center',
            mt: [4, 4, 4],
            mx: [4, 5, 5],
          }}
        >
          <Styled.h3 sx={{ variant: 'text.heading' }}>{tag}</Styled.h3>
        </AnimatedBox>
        <AnimatedBox
          style={subtitleFade}
          sx={{
            px: [4, 5, 6],
            textAlign: 'center',
          }}
        >
          <Styled.h4 sx={{ variant: 'text.subheading' }}>{summary}</Styled.h4>
        </AnimatedBox>
        {animation && (
          <AnimatedBox mx="auto" style={animationFade}>
            <ProjectAnimation animationName={animationName} />
          </AnimatedBox>
        )}
        <AnimatedBox mt={animation ? 3 : 5} mb={internetStage && contractAddress ? 4 : 5} mx="auto" sx={{ border: '4px solid white', borderRadius: '6px', maxWidth: '600px', boxShadow: (theme) => `0 0px 3px 0 ${theme.colors.muted}, 0 1px 2px 0 ${theme.colors.mutedSecondary}` }}>
          <ProjectHeroImage heroImageName={heroImageName} heroImage={heroImage.childImageSharp.fluid} />
        </AnimatedBox>
        {internetStage && contractAddress && (
          <AnimatedBox
            style={animationFade}
            sx={{
              width: ['100%', '100%', '100%'],
              pt: [4, 4, 4, 4],
              pb: [5, 5, 5, 5],
              textAlign: 'center',
            }}
          >
            <a href={`${contractAddress}`} target="_blank" rel="noopener noreferrer" sx={{ variant: 'links.web3' }}>
              View the contract ↗️
            </a>
          </AnimatedBox>
        )}
      </Container>
    </Fragment>
  )
}

export default ProjectHero
