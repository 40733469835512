/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { Fragment, useState, useEffect } from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import AvantiTag from '../assets/avantiTag'

const AvantiTagContainer = () => {
  const [toggelAnimation, setToggelAnimation] = useState(false)

  const tagLineColor = '#dd2028' //need to set static otherwise we have issue with Framer Motion

  useEffect(() => {
    let animationInterval = setInterval(() => setToggelAnimation((prevState) => !prevState), 12000)

    return () => {
      clearInterval(animationInterval) // this will clear Interval when the component unmounts like in willComponentUnmount
    }
  }, []) //useEffect will run only one time. If you pass a value to array, then clearInterval will run every time that value changes (useEffect re-run)

  return (
    <Fragment>
      <Flex
        sx={{
          width: ['40%', '35%', '30%', '25%'],
          margin: 'auto',
        }}
      >
        <motion.div
          viewport={{ once: true }}
          sx={{
            display: 'inline-block',
            position: 'relative',
            // height: '200px',
            width: '100%',
            paddingBottom: '40%',
            verticalAlign: 'middle',
            // overflow: 'hidden',
          }}
        >
          {toggelAnimation && (
            <AvantiTag
              width="100%" // should match width of the container above
              viewBoxWidthFactor="1"
              viewBoxHeightFactor="1"
              viewBoxX="0"
              viewBoxY="0"
              color={tagLineColor}
            />
          )}

          {!toggelAnimation && (
            <AvantiTag
              width="100%" // should match width of the container above
              viewBoxWidthFactor="1"
              viewBoxHeightFactor="1"
              viewBoxX="0"
              viewBoxY="0"
              color={tagLineColor}
            />
          )}
        </motion.div>
      </Flex>
    </Fragment>
  )
}

export default AvantiTagContainer
