/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import Heart from '../assets/heart'
import PatersonsLogo from '../assets/paterson'
import AvantiTagContainer from './avantiTagContainer'

const AnimatedBox = animated(Box)

const ProjectAnimation = ({ animationName }) => {
  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  })

  return (
    <Fragment>
      <AnimatedBox
        style={headingFade}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {animationName === 'heart' && <Heart color="#FF859D" />}
        {animationName === 'paterson' && <PatersonsLogo />}
        {animationName === 'avantiTag' && <AvantiTagContainer />}
      </AnimatedBox>
    </Fragment>
  )
}

export default ProjectAnimation
