/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion/dist/framer-motion'
import { pathVariantF, pathVariantO, pathVariantR, pathVariantW, pathVariantA, pathVariantRR, pathVariantD, pathVariantPeriodOne, pathVariantT, pathVariantOO, pathVariantG, pathVariantE, pathVariantTT, pathVariantH, pathVariantEE, pathVariantRRR, pathVariantPeriodTwo } from './avantiTagPathVariants'

// Width AND Height control the size of the CANVAS, AND therefore the SVG inside it
// viewBoxWidth AND viewBoxHeight control the 'cropping' of the SVG inside the Canvas (does NOT change the size of the Canvas)

// viewBoxX AND viewBoxY control the position of the Canvas that 'holds' the SVG, which simulates 'cropping'

// To adjust 'fit' inside a fixed width / height container:
// set the Width AND Height to match the container
// adjust viewBoxWidth AND viewBoxHeight to crop to the size you want
// adjust viewBoxX and viewBoxY to position inside the container
// set CSS to: contain: 'content'

// The SVG's aspect ratio is scaled automatically unless we explicitly set the 'preserveAspectRatio' OR you can 'fix' the Width or Height (i.e. in pixels)

function AvantiTag({ width = '100%', height, viewBoxWidth = 644, viewBoxHeight = 262, viewBoxWidthFactor = 1, viewBoxHeightFactor = 1, viewBoxX = 0, viewBoxY = 0, color = '#022844' }) {
  const vBwidth = viewBoxWidth * viewBoxWidthFactor
  const vBheight = viewBoxHeight * viewBoxHeightFactor

  // SVG as a whole
  const svgVariant = {
    initial: {
      scale: 1,
      opacity: 1,
      x: 0,
    },
    animate: {
      scale: 0.1,
      opacity: 1,
      x: '100vw',
      transition: {
        delay: 10,
        duration: 4,
        ease: 'easeInOut',
        // type: 'spring',
        // bounce: 0.4,
      },
    },
  }

  return (
    <motion.svg
      variants={svgVariant}
      initial="initial"
      animate="animate"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox={`${viewBoxX} ${viewBoxY} ${vBwidth} ${vBheight}`}
      // viewBox="0 0 644.384 262.586"
      width={width}
      height={height}
      preserveAspectRatio="xMinYMin meet"
      style={{
        display: 'inline-block',
        position: 'absolute',
        top: '0',
        left: '0',
      }}
    >
      {/* <g fill={color}> */}
      <g fill="none">
        <motion.path stroke={color} strokeWidth="2" strokeLinecap="square" variants={pathVariantF(color)} initial="hidden" animate="visible" whileHover="hover" d="M102.896 57.293v12.9H71.694v16.402h25.602v12.3H71.694v28.403H56.693V57.293z" shapeRendering="crispEdges"></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantO(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M158.397 123.297a37.52 37.52 0 01-36.002 0 32.11 32.11 0 01-12.3-12.75 37.793 37.793 0 01-4.4-18.252 37.795 37.795 0 014.4-18.251 32.11 32.11 0 0112.3-12.75 37.517 37.517 0 0136.002 0 32.116 32.116 0 0112.3 12.75 37.795 37.795 0 014.4 18.251 37.793 37.793 0 01-4.4 18.251 32.117 32.117 0 01-12.3 12.751zm-28.201-11.05a18.936 18.936 0 0020.3 0 19.29 19.29 0 006.801-8.15 27.86 27.86 0 002.4-11.801 27.858 27.858 0 00-2.4-11.8 19.29 19.29 0 00-6.8-8.15 18.936 18.936 0 00-20.301 0 19.283 19.283 0 00-6.8 8.15 27.843 27.843 0 00-2.4 11.8 27.846 27.846 0 002.4 11.8 19.283 19.283 0 006.8 8.15z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantR(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M226.145 60.143a21.085 21.085 0 019.05 8.151 23.335 23.335 0 013.2 12.3 21.992 21.992 0 01-3.2 11.851 20.729 20.729 0 01-8.8 7.85l13.8 27.002h-16.6l-11.7-23.502h-12.702v23.502h-15.001V57.293h28.502a30.347 30.347 0 0113.451 2.85zm-14.651 31.153a11.99 11.99 0 008.45-2.9 10.088 10.088 0 003.15-7.8 10.211 10.211 0 00-3.15-7.901 11.991 11.991 0 00-8.45-2.9h-12.301v21.5z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantW(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M256.795 57.193l12.9 51.203 13.701-51.103h13.501l13.3 51.103 13.2-51.103H338.6l-19.401 70.005h-17.301l-12.1-46.504-11.801 46.504h-17.302l-19.4-70.005z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantA(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M357.395 57.293h18.001l25.401 70.005h-16.1l-5.4-15.301h-26.602l-5.4 15.3h-15.501zm17.8 42.603l-9.3-26.602-9.2 26.602z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantRR(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M449.147 60.143a21.086 21.086 0 019.05 8.151 23.336 23.336 0 013.2 12.3 21.992 21.992 0 01-3.2 11.851 20.73 20.73 0 01-8.8 7.85l13.8 27.002h-16.6l-11.7-23.502h-12.702v23.502h-15.001V57.293h28.502a30.347 30.347 0 0113.451 2.85zm-14.651 31.153a11.99 11.99 0 008.45-2.9 10.088 10.088 0 003.15-7.8 10.211 10.211 0 00-3.15-7.901 11.991 11.991 0 00-8.45-2.9h-12.301v21.5z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantD(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M495.695 57.293a37.708 37.708 0 0118.55 4.45 30.844 30.844 0 0112.402 12.401 37.191 37.191 0 014.35 18.151 37.19 37.19 0 01-4.35 18.151 30.841 30.841 0 01-12.401 12.401 37.703 37.703 0 01-18.551 4.45h-24.302V57.294zm14.75 51.353q5.25-6.15 5.25-16.35t-5.25-16.351q-5.25-6.151-14.75-6.15h-9.3v45.002h9.3q9.5 0 14.75-6.151z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantPeriodOne(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M541.693 111.196a8.31 8.31 0 016.05 2.4 8.053 8.053 0 012.45 6 7.892 7.892 0 01-2.45 5.951 9.027 9.027 0 01-12.15 0 7.975 7.975 0 01-2.4-5.95 8.097 8.097 0 012.4-6.05 8.358 8.358 0 016.1-2.35z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path stroke={color} strokeWidth="2" strokeLinecap="square" variants={pathVariantT(color)} initial="hidden" animate="visible" whileHover="hover" d="M112.096 135.289v12.901h-20.2v57.103H76.893V148.19H56.693v-12.901z" data-name="fullLogo" shapeRendering="crispEdges"></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantOO(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M165.396 201.294a37.524 37.524 0 01-36.002 0 32.114 32.114 0 01-12.3-12.751 40.051 40.051 0 010-36.503 32.105 32.105 0 0112.3-12.75 37.517 37.517 0 0136.002 0 32.111 32.111 0 0112.3 12.75 40.051 40.051 0 010 36.503 32.12 32.12 0 01-12.3 12.75zm-28.201-11.051a18.936 18.936 0 0020.3 0 19.29 19.29 0 006.801-8.15 30.22 30.22 0 000-23.602 19.29 19.29 0 00-6.8-8.15 18.936 18.936 0 00-20.301 0 19.284 19.284 0 00-6.8 8.15 30.208 30.208 0 000 23.602 19.283 19.283 0 006.8 8.15z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantG(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M204.643 139.09a37.124 37.124 0 0118.451-4.4 33.718 33.718 0 0114.902 3.2 27.226 27.226 0 0110.65 9.001 28.84 28.84 0 014.95 13.3h-14.8a16.67 16.67 0 00-5.601-9.35 15.993 15.993 0 00-10.501-3.45 17.633 17.633 0 00-10.1 2.9 18.544 18.544 0 00-6.6 8.1 29.14 29.14 0 00-2.301 11.902q0 10.3 5.05 16.6t13.95 6.3a19.361 19.361 0 0011.501-3.55 14.75 14.75 0 006-10.45v-.9h-17.4v-11.9h31.302v38.903h-10.4l-1.6-8.7a23.162 23.162 0 01-8.3 6.7 26.727 26.727 0 01-12.101 2.6 34.03 34.03 0 01-17.452-4.4 29.877 29.877 0 01-11.7-12.5 40.458 40.458 0 01-4.15-18.702 39.988 39.988 0 014.2-18.752 29.733 29.733 0 0112.05-12.452z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantE(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M313.094 135.289v12.901h-32.002v15.701h26.002v12.301h-26.002v16.201h33.103v12.9H266.09V135.29z"
          data-name="fullLogo"
          shapeRendering="crispEdges"
        ></motion.path>
        <motion.path stroke={color} strokeWidth="2" strokeLinecap="square" variants={pathVariantTT(color)} initial="hidden" animate="visible" whileHover="hover" d="M373.595 135.289v12.901h-20.2v57.103h-15.002V148.19h-20.201v-12.901z" data-name="fullLogo" shapeRendering="crispEdges"></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantH(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M396.791 135.289v28.102h27.802V135.29h15.001v70.005h-15v-28.902H396.79v28.902h-15v-70.005z"
          data-name="fullLogo"
          shapeRendering="crispEdges"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantEE(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M498.493 135.289v12.901H466.49v15.701h26.002v12.301H466.49v16.201h33.102v12.9H451.49V135.29z"
          data-name="fullLogo"
          shapeRendering="crispEdges"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantRRR(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M550.543 138.14a21.088 21.088 0 019.051 8.15 23.336 23.336 0 013.2 12.3 21.992 21.992 0 01-3.2 11.85 20.726 20.726 0 01-8.8 7.851l13.8 27.002h-16.601l-11.7-23.5H523.59v23.5H508.59V135.29h28.502a30.34 30.34 0 0113.452 2.85zm-14.65 31.152a11.99 11.99 0 008.45-2.9 10.09 10.09 0 003.15-7.801 10.21 10.21 0 00-3.15-7.9 11.99 11.99 0 00-8.45-2.9H523.59v21.502z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
        <motion.path
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          variants={pathVariantPeriodTwo(color)}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          d="M579.19 189.193a8.311 8.311 0 016.05 2.4 8.055 8.055 0 012.45 6 7.892 7.892 0 01-2.45 5.95 9.029 9.029 0 01-12.15 0 7.974 7.974 0 01-2.4-5.95 8.098 8.098 0 012.4-6.05 8.357 8.357 0 016.1-2.35z"
          data-name="fullLogo"
          shapeRendering="auto"
        ></motion.path>
      </g>
    </motion.svg>
  )
}

export default AvantiTag
