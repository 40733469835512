/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Fragment } from 'react'
import { animated } from 'react-spring'
import Img from 'gatsby-image'

const AnimatedBox = animated(Box)

export const ProjectHeroImage = ({ heroImage, heroImageName }) => {
  return (
    <Fragment>
      <AnimatedBox>
        <Img
          fluid={heroImage}
          title={heroImageName}
          alt={heroImageName}
          style={{
            maxHeight: '100%',
          }}
          imgStyle={{
            objectFit: 'contain',
            padding: '0.5rem',
          }}
        />
      </AnimatedBox>
    </Fragment>
  )
}
