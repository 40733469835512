/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Fragment } from 'react'
import { animated } from 'react-spring'
import Img from 'gatsby-image'

const AnimatedBox = animated(Box)

export const AuthorImage = ({ authorImage, authorName }) => {
  return (
    <Fragment>
      <AnimatedBox>
        <Img
          fluid={authorImage}
          title={authorName}
          alt={authorName}
          // style={{
          //   maxHeight: '100%',
          // }}
          sx={{
            borderRadius: '50%',
            width: '44px',
            border: (theme) => `2px solid ${theme.colors.subHeading}`,
          }}
          imgStyle={{
            objectFit: 'contain',
            // padding: '0.5rem',
          }}
        />
      </AnimatedBox>
    </Fragment>
  )
}
