/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from './layout'
import SEO from './SEO/seo'
import ProjectHero from './projectHero'
import ProjectHow from './projectHow'
import ProjectFeatures from './projectFeatures'
import ProjectVisit from './projectVisit'
import ButtonBlock from './buttonBlock'
import ContactSection from './contactSection'

const Project = ({ project }) => {
  return (
    <Layout>
      <SEO title={project.name} description={project.techTag} />
      <ProjectHero projectDetails={project} />
      <ProjectHow projectDetails={project} />
      <ProjectFeatures projectDetails={project} />
      <ProjectVisit projectURL={project.url} caseStudy={project.caseStudy} projectComingSoon={project.comingSoon} />
      {/* <MoreFeaturedProjects /> */}
      <ButtonBlock variant="outline" buttonLabel="See more work" linkToFirstButton="/work/" />
      <ContactSection variant="contact" heading="Like to know more?" linkToFirstButton="/contact/" />
    </Layout>
  )
}

export default Project
