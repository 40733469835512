/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import Img from 'gatsby-image'

export const ProjectFeatureImage = ({ image, title }) => {
  
  return (
    <Fragment>
      <Img
        fluid={image}
        title={title}
        alt={title}
        style={{
          maxHeight: '100%',
        }}
        imgStyle={{
          objectFit: 'contain',
          // padding: '0.5rem',
        }}
      />
    </Fragment>
  )
}
