/** @jsx jsx */
import { jsx,Card} from 'theme-ui'
import { Fragment } from 'react'
import { animated } from 'react-spring'
import { ProjectFeatureImage } from './projectFeatureImage'

const AnimatedCard = animated(Card)

const ProjectFeaturesCard = ({ title, content, image, cardWidth = '500px', imgWidth = '200px' }) => {

  // const cardAnimation = useSpring({
  //   from: hovered ? { transform: 'translate(0px, 0px)' } : { transform: 'translate(0px, 0px)' },
  //   to: hovered ? { transform: `translate(0px, -10px)` } : { transform: 'translate(0px, 0px)' },
  //   config: hovered ? { duration: 150 } : { duration: 150 },
  // })

  return (
    <Fragment>
      <AnimatedCard
        // onMouseOver={() => setHover(true)}
        // onMouseOut={() => setHover(false)}
        // style={cardAnimation}
        variant="primary"
        sx={{ border: '10px solid white', borderRadius: '6px', width: cardWidth, m: [0, 0, 0], p: [0, 0, 0], boxShadow: (theme) => `0 0px 3px 0 ${theme.colors.muted}, 0 1px 2px 0 ${theme.colors.mutedSecondary}`, position: 'relative' }}>
        
        <ProjectFeatureImage title={title} image={image} />
        
      </AnimatedCard>
    </Fragment>
  )
}

export default ProjectFeaturesCard
