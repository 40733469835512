const easingType = 'linear'
// const easingTypePeriod = 'easeIn'
const periodType = 'spring'
const periodDelay = 1.9
const periodDuration = 1
const periodBounce = 0.3
const initialAnimationTime = 5
const delayAnimationTime = (time) => initialAnimationTime + time

// Individual elements below
export const pathVariantF = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: initialAnimationTime,
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantO = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 0.3,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.3),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantR = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 0.4,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.4),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantW = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 0.5,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.5),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantA = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 0.6,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.6),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantRR = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 0.7,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.7),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantD = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 0.8,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.8),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantPeriodOne = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
      y: '-100vh',
    },
    visible: {
      opacity: 1,
      y: 0,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: periodDelay,
        duration: periodDuration,
        type: periodType,
        bounce: periodBounce,
        // ease: easingTypePeriod,
        // repeat: Infinity,
        repeatType: 'reverse',
        // repeatDelay: delayAnimationTime(0.2),
      },
    },
    hover: {
      fill: '#dd2028',
    },
  }
  return varObject
}

export const pathVariantT = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 1,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.7),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantOO = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 1.1,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.8),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantG = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 1.2,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(0.9),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantE = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 1.3,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(1),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantTT = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 1.4,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(1.1),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantH = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 1.5,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(1.2),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantEE = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 1.6,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(1.3),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantRRR = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
    },
    visible: {
      opacity: 1,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: 1.7,
        duration: 1,
        ease: easingType,
        // repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: delayAnimationTime(1.4),
      },
    },
    hover: {
      fill: '#ffffff',
    },
  }
  return varObject
}

export const pathVariantPeriodTwo = (color) => {
  const varObject = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: '#fff',
      y: '100vh',
    },
    visible: {
      opacity: 1,
      y: 0,
      fill: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', color],
      pathLength: 1,
      transition: {
        delay: periodDelay,
        duration: periodDuration,
        type: periodType,
        bounce: periodBounce,
        // ease: easingTypePeriod,
        // repeat: Infinity,
        repeatType: 'reverse',
        // repeatDelay: delayAnimationTime(0.2),
      },
    },
    hover: {
      fill: '#dd2028',
    },
  }
  return varObject
}
