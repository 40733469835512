/** @jsx jsx */
import { jsx, Box, Styled, Flex, Grid } from 'theme-ui'
import { Fragment } from 'react'
import ProjectFeaturesCard from './projectFeaturesCard'
import Container from './container'

const ProjectFeatures = ({ projectDetails }) => {
  const { features } = projectDetails

  return (
    <Fragment>
      <Container sx={{ my: 0, py: 2 }}>
        <Flex sx={{ p: '2', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', my: [3, 5, 5] }}>
          <Box sx={{ mx: 0, bg: 'secondary', width: '80px', height: '4px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.text}`, borderRadius: '2px' }} />
          <Box sx={{ mx: 2 }}>
            <Styled.p sx={{ fontSize: '3', variant: 'text.caps', color: 'projectFeatures' }}>Features</Styled.p>
          </Box>
        </Flex>

        <Grid sx={{ mt: [0, 0, 0], pb: [2, 2, 2], mx: [0, 0, 0] }} gap={4} columns={[`auto`, `auto`, `auto auto`]} variant="cards">
          {features.map((item, i) => (
            <Flex key={i} sx={{ flexDirection: 'column' }}>
              <Styled.h5 sx={{ variant: 'text.subheading', color: 'projectFeatures' }}>{item.title}</Styled.h5>
              <Flex key={i} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <ProjectFeaturesCard title={item.title} content={item.content} image={item.image.childImageSharp.fluid} />
              </Flex>
            </Flex>
          ))}
        </Grid>
      </Container>
    </Fragment>
  )
}

export default ProjectFeatures
