/** @jsx jsx */
import { jsx, Box, Styled, Flex, useThemeUI } from 'theme-ui'
import { Fragment, useState, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import Container from './container'
import ProjectFrontend from './projectFrontend'
import ProjectBackend from './projectBackend'
import ProjectDeployment from './projectDeployment'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)

const ProjectHow = ({ projectDetails }) => {
  const { techTag, techSummary, name, internetStage, nftMarketplace, contractAddress } = projectDetails

  const [projectColor, setProjectColor] = useState('#fff')
  const [projectCopy, setProjectCopy] = useState('#fff')

  const context = useThemeUI()
  const { theme } = context

  useEffect(() => {
    const projectName = name
    if (projectName === 'SpotEx') {
      setProjectColor(theme.colors.spotexColor)
      setProjectCopy(theme.colors.projectCopy)
    }
    if (projectName === 'BricktOrigins') {
      setProjectColor(theme.colors.patersonColor)
      setProjectCopy(theme.colors.projectCopy)
    }
    if (projectName === 'Paterson Architects') {
      setProjectColor(theme.colors.patersonColor)
      setProjectCopy(theme.colors.projectCopy)
    }
    if (projectName === 'Riskwest') {
      setProjectColor(theme.colors.riskwestColor)
      setProjectCopy(theme.colors.projectCopy)
    }
    if (projectName === 'Rebecca Faith Yates') {
      setProjectColor(theme.colors.rfyColor)
      setProjectCopy(theme.colors.rfyCopy)
    }
    if (projectName === 'Avanti Financial Planning') {
      setProjectColor(theme.colors.avantiColor)
      setProjectCopy(theme.colors.avantiCopy)
    }
    if (projectName === 'Dwight Club') {
      setProjectColor(theme.colors.dwightColor)
      setProjectCopy(theme.colors.projectCopy)
    }
    if (projectName === 'Sauce Candy') {
      setProjectColor(theme.colors.sauceCandyColor)
      setProjectCopy(theme.colors.projectCopy)
    }
    if (projectName === 'KungPao DAO') {
      setProjectColor(theme.colors.kungpaoColor)
      setProjectCopy(theme.colors.projectCopy)
    }
  }, [name, theme.colors.spotexColor, theme.colors.patersonColor, theme.colors.riskwestColor])

  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <div sx={{ bg: projectColor, width: '100vw', position: 'relative', ml: '-50vw', left: '50%' }}>
        <Container sx={{ my: 0, py: 2 }}>
          <Flex sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', my: [3, 5, 5] }}>
            <Box sx={{ mx: 0, bg: projectCopy, width: '80px', height: '4px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.projectCopy}`, borderRadius: '2px' }} />
            <Box sx={{ mx: 2 }}>{internetStage === 'web2' ? <Styled.p sx={{ fontSize: '3', variant: 'text.caps', color: projectCopy }}>How it was done</Styled.p> : <Styled.p sx={{ fontSize: '3', variant: 'text.caps', color: projectCopy }}>About</Styled.p>}</Box>
          </Flex>
          <AnimatedBox
            style={subtitleFade}
            sx={{
              mt: [4, 4, 4],
              // mx: [4, 4, 4],
              px: [2, 5, 6],
              textAlign: 'center',
            }}
          >
            <Styled.h4 sx={{ variant: 'text.subheading', color: projectCopy }}>{techTag}</Styled.h4>
          </AnimatedBox>
          <AnimatedBox
            style={subtitleFade}
            sx={{
              width: ['100%', '92%', '92%'],
              mx: 'auto',
              mb: [5, 5, 5],
              pb: [1, 3, 3],
              // px: [4, 5, 7],
              px: [1, 5, 6],
              textAlign: 'center',
            }}
          >
            {techSummary.map((item) =>
              item.title === 'One' ? (
                <Styled.h5 key={item.title} sx={{ variant: 'text.subheading', color: projectCopy }}>
                  {item.content}
                </Styled.h5>
              ) : (
                <Styled.p sx={{ fontSize: ['2', '3', '3'], variant: 'text.about', color: projectCopy, mb: 0 }} key={item.title}>
                  {item.content}
                </Styled.p>
              ),
            )}
          </AnimatedBox>
          {internetStage === 'web2' && (
            <Fragment>
              <ProjectFrontend projectDetails={projectDetails} projectCopyColor={projectCopy} />
              <ProjectBackend projectDetails={projectDetails} projectCopyColor={projectCopy} />
              <ProjectDeployment projectDetails={projectDetails} projectCopyColor={projectCopy} />
            </Fragment>
          )}
          {internetStage === 'web3' && (
            <Fragment>
              <Flex sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', mt: [3, 5, 5, 5], mb: [3, 3, 3, 3] }}>
                <Box sx={{ mx: 0, bg: projectCopy, width: '80px', height: '4px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.projectCopy}`, borderRadius: '2px' }} />
                <Box sx={{ mx: 2 }}>
                  {' '}
                  <Styled.p sx={{ fontSize: '3', variant: 'text.caps', color: projectCopy }}>Links</Styled.p>{' '}
                </Box>
              </Flex>
              <AnimatedFlex
                style={subtitleFade}
                sx={{
                  flexDirection: 'column',
                  width: ['100%', '100%', '100%', '100%'],
                  pt: [3, 3, 3, 3],
                  pb: [3, 3, 3, 3],
                  mb: [4, 4, 4, 4],
                  textAlign: 'center',
                }}
              >
                {contractAddress && (
                  <div sx={{ py: 2 }}>
                    <a href={`${contractAddress}`} target="_blank" rel="noopener noreferrer" sx={{ variant: 'links.web3Alt', py: 2 }}>
                      View the contract ↗️
                    </a>
                  </div>
                )}
                {nftMarketplace && (
                  <div sx={{ py: 2 }}>
                    <a href={`${nftMarketplace}`} target="_blank" rel="noopener noreferrer" sx={{ variant: 'links.web3Alt' }}>
                      View on OpenSea ↗️
                    </a>
                  </div>
                )}
              </AnimatedFlex>
            </Fragment>
          )}
        </Container>
      </div>
    </Fragment>
  )
}

export default ProjectHow
