import React from 'react'
import { graphql } from 'gatsby'
import Project from '../components/project'

export const query = graphql`
  query A_SINGLE_PROJECT_QUERY($slug: String!) {
    projectsJson(slug: { eq: $slug }) {
      author
      authorName
      authorImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      authorTwitter
      authorLinkedIn
      authorEns
      authorLens
      authorGithub
      authorWebsite
      icon {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iconName
      name
      clientType
      internetStage
      contractAddress
      nftMarketplace
      productType
      url
      comingSoon
      caseStudy
      tag
      summary
      animation
      animationName
      techTag
      techSummary {
        title
        content
      }
      heroImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroImageName
      frontendImageCount
      frontend {
        images {
          name
          src {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content {
          title
          content
        }
      }
      backendImageCount
      backend {
        images {
          name
          src {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content {
          title
          content
        }
      }
      deploymentImageCount
      deployment {
        images {
          name
          src {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content {
          title
          content
        }
      }
      features {
        title
        content
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      description
      stack
    }
  }
`

const ProjectPageTemplate = ({ data }) => {
  const project = data.projectsJson
  return <Project project={project} />
}

export default ProjectPageTemplate
