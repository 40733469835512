/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment, useState, useLayoutEffect } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import { ButtonAnimated } from './animation'
import { Waypoint } from 'react-waypoint'
import Container from '../components/container'

const AnimatedFlex = animated(Flex)

const ButtonBlock = ({ variant = 'primary', linkToFirstButton = '/', buttonLabel = 'Get in touch', secondButton = false, secondLabel, variantSecondButton = 'outline', linkToSecondButton = '/' }) => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const buttonFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 350 : 0,
  })

  return (
    <Fragment>
      <Container sx={{ mt: [3, 4, 5] }}>
        <Waypoint
          bottomOffset="25%"
          onEnter={() => {
            if (!active) setActive(true)
          }}
        />
        <Flex sx={{ variant: 'layout.sectionHeroContainerDefault' }}>
          <AnimatedFlex
            style={buttonFade}
            sx={{
              variant: 'layout.buttonContainerDefault',
            }}
          >
            <Box sx={{ my: [3, 3, 3, 3], mx: [1, 1, 3, 3] }}>
              <ButtonAnimated margin="0" variant={variant} scaleActive="1.1" label={buttonLabel} linkTo={linkToFirstButton} />
            </Box>

            {secondButton && (
              <Box sx={{ my: [3, 3, 3, 3], mx: [1, 1, 3, 3] }}>
                <ButtonAnimated margin="0" variant={variantSecondButton} scaleActive="1.1" label={secondLabel} linkTo={linkToSecondButton} />
              </Box>
            )}
          </AnimatedFlex>
        </Flex>
      </Container>
    </Fragment>
  )
}

export default ButtonBlock
