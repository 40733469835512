/** @jsx jsx */
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion/dist/framer-motion'

function HeartIcon({ width = '80px', height = '80px', viewBoxWidth = 3061.42, viewBoxHeight = 3061.42, viewBoxWidthFactor = 1, viewBoxHeightFactor = 1, viewBoxX = 0, viewBoxY = 0, color, iconName = 'heart' }) {
  const vBwidth = viewBoxWidth * viewBoxWidthFactor
  const vBheight = viewBoxHeight * viewBoxHeightFactor

  const transition = { duration: 3, yoyo: Infinity, ease: 'easeInOut' }

  return (
    <motion.svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox={`${viewBoxX} ${viewBoxY} ${vBwidth} ${vBheight}`} width={width} height={height}>
      {iconName === 'heart' && (
        <motion.path
          // initial={{ pathLength: 0 }}
          // animate={{ pathLength: 1.5 }}
          initial={{ offsetDistance: '0%', scale: 0.75, strokeWidth: 20 }}
          animate={{ offsetDistance: '100%', scale: 1, strokeWidth: 100 }}
          transition={transition}
          fill={color}
          // strokeWidth="150"
          // strokeLinecap="round"
          stroke={color}
          d="M2033.72,508.66q133.64,0,248.81,50.73,115.08,51,200.72,136.49a582.63,582.63,0,0,1,139.12,200.71q48.11,115.08,48.1,248.82a635.5,635.5,0,0,1-13.29,128.47,916.57,916.57,0,0,1-34.81,123,1100.89,1100.89,0,0,1-61.6,107.05,474.37,474.37,0,0,1-82.9,96.3l-947.16,952.54-963.2-968.58a532.12,532.12,0,0,1-75-93.65,918.14,918.14,0,0,1-58.85-109.7,573.65,573.65,0,0,1-32.07-115.07A693.42,693.42,0,0,1,391,1145.41q0-133.64,48.1-248.82A581.14,581.14,0,0,1,578.17,695.88q85.55-85.54,200.71-136.49,114.93-50.67,248.82-50.73a672.36,672.36,0,0,1,147.14,16,587,587,0,0,1,136.48,48.1,663.37,663.37,0,0,1,120.35,77.63,551.69,551.69,0,0,1,99,104.32,547.93,547.93,0,0,1,99-104.32,663,663,0,0,1,120.35-77.63,587,587,0,0,1,136.48-48.1,671.63,671.63,0,0,1,147.14-16m0,90.92q-85.7,0-163.17,24-77.64,24.21-141.86,66.87a673.78,673.78,0,0,0-115.08,109.8,557.51,557.51,0,0,0-82.9,136.38A560.64,560.64,0,0,0,1447.8,800.3a677.79,677.79,0,0,0-115.07-109.8q-64.23-42.62-141.86-66.87-77.62-24-163.17-24-112.34,0-211.37,42.82T642.4,760.11q-75,75-117.71,173.93-42.92,99.09-42.82,211.37a546.67,546.67,0,0,0,10.65,107.06,876.71,876.71,0,0,0,26.79,101.67,556.68,556.68,0,0,0,50.84,90.92,524.15,524.15,0,0,0,61.6,74.89l899,904.35,882.93-888.22a751.26,751.26,0,0,0,69.61-80.26,469.68,469.68,0,0,0,53.47-91,609.9,609.9,0,0,0,32.07-107.06,572.79,572.79,0,0,0,10.76-112.33q0-112.34-42.83-211.37T2419,760.11q-75-74.88-173.93-117.71-99.09-42.76-211.37-42.82"
        />
      )}
    </motion.svg>
  )
}

export default HeartIcon
