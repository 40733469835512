/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import Img from 'gatsby-image'

export const ProjectStackImage = ({ src, title }) => {
  return (
    <Fragment>
      <Img
        fluid={src}
        title={title}
        alt={title}
        style={{
          height: '100px',
        }}
        imgStyle={{
          objectFit: 'contain',
          padding: '0.75rem',
          // border: '1px solid red',
        }}
      />
    </Fragment>
  )
}
