/** @jsx jsx */
import { jsx, Styled, Flex, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
// import ExternalLinkIcon from '../assets/externalLink'

const ProjectVisit = ({ projectURL, caseStudy, projectComingSoon }) => {
  const context = useThemeUI()
  const { theme } = context
  return (
    <Fragment>
      <Flex sx={{ p: '2', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mt: [3, 5, 5], mb: [0, 0, 0] }}>
        {projectComingSoon ? (
          <Fragment>
            <Flex sx={{ mx: 2 }}>
              <Styled.p sx={{ fontSize: '3', variant: 'text.caps' }}>Site Coming Soon</Styled.p>
            </Flex>
          </Fragment>
        ) : (
          <Fragment>
            {projectURL.length === 0 && (
              <Flex sx={{ mx: 2 }}>
                <Styled.p sx={{ fontSize: '3', variant: 'text.caps' }}>
                  <span>Original site is no longer live</span>
                </Styled.p>
              </Flex>
            )}
            {projectURL.length > 0 && (
              <Flex sx={{ mx: 2 }}>
                <a href={projectURL}>
                  <Styled.p sx={{ fontSize: '3', variant: 'text.caps' }}>
                    <span
                      sx={{
                        color: theme.colors.headingSecondary,
                        position: 'relative',
                        display: 'inline-block',
                        '::after': {
                          content: '""',
                          position: 'absolute',
                          zIndex: '-1',
                          top: '60%',
                          left: '-0.1em',
                          right: '-0.1em',
                          bottom: '0',
                          backgroundColor: theme.colors.externalLinkTo,
                          opacity: 0.5,
                          transition: 'top 700ms ease-out',
                        },
                        ':hover:after': {
                          opacity: 0.5,
                          top: '5%',
                        },
                      }}
                    >
                      Visit Site
                    </span>
                  </Styled.p>
                </a>
              </Flex>
            )}
            {/* <Flex sx={{ mx: 2, width: '60px', height: '60px' }}>
              <a href={projectURL} title={projectURL}>
                <ExternalLinkIcon caseStudy={caseStudy} />
              </a>
            </Flex> */}
          </Fragment>
        )}
      </Flex>
    </Fragment>
  )
}

export default ProjectVisit
